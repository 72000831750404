<template>
  <section>
    <div class="control">
      <b-switch v-model="hasMobileCards">Mobile View </b-switch>
    </div>
    <div v-if="data">
      <b-table
        v-if="!isLoading"
        class="mb-4 mr-2"
        :data="data"
        :mobile-cards="hasMobileCards"
        :loading="isLoading"
        :key="tableDataKey"
      >
        <b-table-column
          field="id"
          label="ID"
          width="10"
          :td-attrs="columnTdAttrs"
          numeric
          v-slot="props"
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column
          field="first_name"
          label="Name"
          width="10"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          <span class="has-text-weight-bold is-size-5">
            {{ props.row.first_name }}
          </span>
        </b-table-column>

        <b-table-column
          field="date"
          label="Purchase Date"
          width="10"
          :th-attrs="dateThAttrs"
          :td-attrs="columnTdAttrs"
          centered
          v-slot="props"
        >
          <span class="tag is-primary">
            {{ new Date(props.row.date).toLocaleDateString() }}
          </span>
        </b-table-column>

        <b-table-column
          field="qty"
          label="Qty"
          width="10"
          :td-attrs="columnTdAttrs"
          numeric
          v-slot="props"
        >
          {{ props.row.qty }}
        </b-table-column>

        <b-table-column
          label="Purchase Price"
          :td-attrs="columnTdAttrs"
          numeric
          v-slot="props"
        >
          <span>
            <!-- <b-icon
            v-if="props.row.id !== 'Total'"
            pack="fas"
            :icon="props.row.purchprice === 'Male' ? 'caret-up' : 'caret-down'"
          >
          </b-icon> -->
            {{ props.row.purchprice }}
          </span>
        </b-table-column>
        <b-table-column
          field="curprice"
          label="Current Price"
          :td-attrs="columnTdAttrs"
          numeric
          v-slot="props"
        >
          {{ props.row.curprice }}
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">No records</div>
        </template>
      </b-table>
    </div>
    <div mt-4>
      <GChart
        type="PieChart"
        :data="chartData"
        :options="chartOptions"
        :key="chartKey"
      />
    </div>
  </section>
</template>

<script>
// import { mapState } from "vuex";
// import { mapActions } from "vuex";
// import store from ".././store";

export default {
  data() {
    const data = [
      {
        id: 1,
        first_name: "XLM",
        cgname: "stellar",
        date: "2021/12/5",
        qty: 3844,
        purchprice: 0.27,
        curprice: null
      },
      {
        id: 2,
        first_name: "XRP",
        date: "2021/12/5",
        qty: 1189,
        purchprice: 0.8,
        curprice: null
      },
      {
        id: 3,
        first_name: "TIME",
        date: "2021/11/13",
        qty: 0.1202,
        purchprice: 7917.0,
        curprice: null
      },
      {
        id: 4,
        first_name: "wsOHM",
        date: "2021/11/23 10:28:46",
        qty: 0.0244,
        purchprice: 35040,
        curprice: null
      },
      {
        id: 5,
        first_name: "BTC",
        date: "2021/12/06",
        qty: 0.015471,
        purchprice: 64641,
        curprice: null
      },
      {
        id: 6,
        first_name: "Silver",
        date: "2022/3/09",
        qty: 22,
        purchprice: 24,
        curprice: null
      }
    ];

    return {
      data,
      stellarPrice: null,
      tableDataKey: 0,
      chartKey: 0,
      // currentPrices: {},
      isLoading: true,
      hasMobileCards: false,
      chartData: [
        ["Asset", "Allocation"],
        ["BTC", 1],
        ["XLM", 1],
        ["XRP", 1],
        ["wsOHM", 1],
        ["TIME", 1],
        ["Silver", 1],
        ["USD", 2794]
      ],
      chartOptions: {
        title: "Percentage Allocation",
        tooltip: { text: "percentage", trigger: "selection" },
        width: 500,
        height: 500
      }
    };
  },

  async mounted() {
    this.isLoading = true;
    // try {
    await this.$store.dispatch("getAllocationPrices");
    await this.$store.dispatch("getSnowData");
    await this.$store.dispatch("getSilverData");
    // } catch (ex) {
    // this.error = "Failed to load data";
    // } finally {
    this.isLoading = false;

    this.data[0].curprice = this.$store.state.allocationPrices["stellar"][
      "usd"
    ];
    this.data[1].curprice = this.$store.state.allocationPrices["ripple"]["usd"];
    this.data[2].curprice = this.$store.state.allocationPrices["wonderland"][
      "usd"
    ];
    this.data[2].qty = (
      parseFloat(this.$store.state.snowtraceData["result"]) * 0.000000001
    ).toFixed(3);

    this.data[3].curprice = this.$store.state.allocationPrices[
      "wrapped-staked-olympus"
    ]["usd"];

    this.data[4].curprice = this.$store.state.allocationPrices["bitcoin"][
      "usd"
    ];
    this.data[5].curprice = this.$store.state.silverData[1]["silver"];

    var d = this.$store.state.allocationPrices;
    this.chartData[1][1] = d["bitcoin"]["usd"] * this.data[4].qty;
    this.chartData[2][1] = d["stellar"]["usd"] * this.data[0].qty;
    this.chartData[3][1] = d["ripple"]["usd"] * this.data[1].qty;
    this.chartData[4][1] =
      d["wrapped-staked-olympus"]["usd"] * this.data[3].qty;
    this.chartData[5][1] = d["wonderland"]["usd"] * this.data[2].qty;
    this.chartData[6][1] =
      this.$store.state.silverData[1]["silver"] * this.data[5].qty;
    this.chartKey++;
  },
  methods: {
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success"
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important"
            }
          };
        } else if (column.label === "purchprice") {
          return {
            class: "has-text-weight-semibold"
          };
        } else {
          return {
            style: { display: "none" }
          };
        }
      }
      return null;
    }
  }
};
</script>
