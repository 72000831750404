<template>
  <div class="tile is-ancestor is-vertical is-12">
    <div class="tile is-parent is-vertical has-text-centered mr-3 ml-3">
      <router-link to="/userbase">
        <article class="tile is-child notification is-primary">
          <b-icon
            class="mt-4"
            icon="target-account"
            custom-size="mdi-96px"
          ></b-icon>
          <h1 class="has-text-white">Acess Userbase</h1>
        </article>
      </router-link>
    </div>
    <div class="tile is-parent is-vertical has-text-centered mr-3 ml-3">
      <router-link to="/admininbox">
        <article class="tile is-child notification is-primary mr-3 ml-3">
          <b-icon class="mt-4" icon="email" custom-size="mdi-96px"></b-icon>
          <h1 class="has-text-white">Inbox</h1>
        </article>
      </router-link>
    </div>
    <div class="tile is-parent is-vertical has-text-centered mr-3 ml-3">
      <router-link to="/messenger">
        <article class="tile is-child notification is-primary ">
          <b-icon
            class="mt-4"
            icon="comment-text-multiple-outline"
            custom-size="mdi-96px"
          ></b-icon>
          <h1 class="has-text-white">Send Messages</h1>
        </article>
      </router-link>
      <!-- <b-button @click="clicked">test</b-button> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import store from ".././store";
// import Admin from "./Admin.vue";
export default {
  components: {},
  // components: { Admin },
  data() {
    const data = [
      { Type: "String", Stage: "Go Fast", Time: 1.9 },
      { Type: "Stage", Stage: "M", Time: 10 }
      //     {'Type': 'Match', 'Stage': '', 'Time': 80}
    ];
    return {
      data,
      name: "",
      title: "",
      showSuccess: false,
      activeColor: "blue"
    };
  },
  computed: {
    ...mapState(["userProfile"]),
    ...mapState(["recordedTimes"])
  },
  created() {
    //todo move these out
    // store.dispatch("fetchHundredTimes");
    // store.dispatch("fetchUserProfile", { uid: userId });
    // store.dispatch("fetchstageNames");
    // store.dispatch("fetchdivisionNames");
  },
  methods: {
    clicked() {
      console.table(this.recordedTimes);
    },
    submitGoal() {
      this.$buefy.notification.open("Clicked!!");
    },
    updateProfile() {
      this.$store.dispatch("getProfile", {
        name: this.name !== "" ? this.name : this.userProfile.name,
        title: this.title !== "" ? this.title : this.userProfile.title
      });
      this.name = "";
      this.title = "";
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 2000);
    },
    fetchData() {}
  }
};
</script>
