<template>
  <section>
    <form
      @submit.prevent="sendData"
      id="create-form"
      class="field mt-6 mr-4 ml-4"
    >
      <b-field>
        <b-select v-model="record.id" placeholder="Select a name">
          <option
            v-for="option in teamNames"
            :value="option.id"
            :key="option.name"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <b-select v-model="record.stage" placeholder="Select a stage">
          <option
            v-for="option in teamStages"
            :value="option.name"
            :key="option.name"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <b-select v-model="record.division" placeholder="Select a division">
          <option v-for="option in teamDivisions" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <b-numberinput
          placeholder="String 1 time"
          v-model="record.string1"
          :controls="false"
          step="0.01"
          min-step="0.01"
          place
        >
        </b-numberinput>
      </b-field>
      <b-field>
        <b-numberinput
          placeholder="String 2 time"
          v-model="record.string2"
          :controls="false"
          step="0.01"
          min-step="0.01"
        >
        </b-numberinput>
      </b-field>

      <b-field>
        <b-numberinput
          placeholder="String 3 time"
          v-model="record.string3"
          :controls="false"
          step="0.01"
          min-step="0.01"
        >
        </b-numberinput>
      </b-field>
      <b-field>
        <b-numberinput
          placeholder="String 4 time"
          v-model="record.string4"
          :controls="false"
          step="0.01"
          min-step="0.01"
        >
        </b-numberinput>
      </b-field>
      <b-field>
        <b-numberinput
          placeholder="String 5 time"
          v-model="record.string5"
          :controls="false"
          step="0.01"
          min-step="0.01"
        >
        </b-numberinput>
      </b-field>

      <div class="field is-grouped">
        <div class="control">
          <button
            class="button"
            x-on:click="contactForm(nm,stage,division,string1,string2,string3,string4,string5)"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from "vuex";
import store from ".././store";
import * as fb from "../firebase";

export default {
  data() {
    return {
      record: {
        id: null,
        stage: null,
        division: null,
        string1: null,
        string2: null,
        string3: null,
        string4: null,
        string5: null
      }
    };
  },
  computed: {
    teamNames() {
      return this.$store.state.teamNames;
    },
    teamStages() {
      return this.$store.state.teamStages;
    },
    teamDivisions() {
      return this.$store.state.teamDivisions;
    },
    teamId() {
      return this.$store.state.userProfile.team;
    },
    ...mapState(["userProfile"])
    // ...mapState(["teamList"])
  },
  created() {
    //todo move these out
    // store.dispatch("fetchteamNames");
    // store.dispatch("fetchUserProfile", { uid: userId });
    // store.dispatch("fetchstageNames");
    // store.dispatch("fetchdivisionNames");
  },
  methods: {
    snackbar() {
      this.$buefy.snackbar.open({
        type: "is-primary",
        duration: 1000,
        message: "Submitted",
        actionText: null
      });
    },
    sendData: function() {
      fb.db.collection("times").add({
        created: Date.now(),
        id: this.record.id,
        team: this.teamId,
        stage: this.record.stage,
        division: this.record.division,
        string1: this.record.string1,
        string2: this.record.string2,
        string3: this.record.string3,
        string4: this.record.string4,
        string5: this.record.string5
      });
      this.snackbar();

      document.getElementById("create-form").reset();
      this.clearData();
    },
    clearData: function() {
      this.record["stage"] = null;
      this.record["string1"] = null;
      this.record["string2"] = null;
      this.record["string3"] = null;
      this.record["string4"] = null;
      this.record["string5"] = null;
      // }
    },
    dumpData: function() {
      store.dispatch("sendRecord", this.record);
      console.log(this.record);
      document.getElementById("create-form").reset();
    }
  }
};
</script>
