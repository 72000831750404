<template>
  <div>
    <section class="mr-2 ml-2"></section>
    <section class="hero is-info mb-1">
      <div class="hero-body">
        <p class="title">
          Reveille Ranch Shoot
        </p>
        <p class="subtitle">
          09/04/21
        </p>
        <section>
          <b-button v-if="coach" type="is-danger" icon-left="delete">
            Delete
          </b-button>

          <b-button
            class="is-pulled-right"
            v-if="coach"
            label="View"
            type="is-success"
            @click="isAttendanceModalActive = true"
          />
        </section>
        <p v-if="coach"></p>
        <p v-else class=" subtitle is-pulled-right">
          Attending?
          <br />
          <b-switch
            class="is-pulled-right ml-3"
            type="is-success"
            size="is-large"
            v-model="isSwitchedCustom"
            true-value="Yes"
            false-value="No"
            passive-type="is-danger"
          >
          </b-switch>
          <br />
          {{ isSwitchedCustom }}
        </p>
      </div>
    </section>
    <!-- <div class="ui buttons big "> -->
    <div class="is-pulled-right">
      <!-- <b-button
        v-if="coach"
        class="is-primary"
        @click="isImageModalActive = true"
      > -->
      <b-icon
        v-if="coach"
        class="mt-4 mr-6 is-primary"
        icon="plus-circle"
        custom-size="mdi-96px"
        type="is-info"
        @click.native="isImageModalActive = true"
      ></b-icon>
      <!-- </b-button> -->
    </div>
    <!-- </section> -->
    <!-- <section class="mr-2 ml-2"></section>
    <section class="hero is-info">
      <div class="hero-body">
        <p class="title">
          Event
        </p>
        <p class="subtitle">
          Date of Event
        </p>
        <p>
          <b-button v-if="coach" type="is-danger" icon-left="delete">
            Delete
          </b-button>
        </p>
        <b-button
          v-if="coach"
          label="View"
          type="is-success"
          @click="isAttendanceModalActive = true"
        />
        <p v-if="coach"></p>
        <p v-else class=" subtitle is-pulled-right">
          Attending?
          <br />
          <b-switch
            class="is-pulled-right ml-3"
            type="is-success"
            size="is-large"
            v-model="isSwitchedCustom"
            true-value="Yes"
            false-value="No"
            passive-type="is-danger"
          >
          </b-switch>
          <br />
          {{ isSwitchedCustom }}
        </p>
      </div>
    </section> -->
    <b-modal
      full-screen
      v-model="isImageModalActive"
      :width="1000"
      scroll="keep"
    >
      <div class="card">
        <div class="card-image">
          <figure class="is-large">
            <b-field class="ml-6 mr-6 has-text-centered" label="Event Name">
              <b-input value=""></b-input>
            </b-field>

            <b-field class="ml-6 mr-6 has-text-centered" label="Event Location">
              <b-input value=""></b-input>
            </b-field>
            <b-datetimepicker
              class="has-text-centered"
              v-model="dateTime"
              inline
            >
            </b-datetimepicker>
            <section class="is-pulled-right mr-2">
              <b-button
                type="is-info mr-2 is-success"
                confirm
                @click="confirmCalendar"
                >confirm
              </b-button>
              <b-button type="is-danger mr-2" confirm @click="cancelCalendar"
                >cancel</b-button
              >
            </section>
          </figure>
        </div>
      </div>
    </b-modal>
    <b-modal
      full-screen
      v-model="isAttendanceModalActive"
      :width="1000"
      scroll="keep"
    >
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              <article class="tile is-child notification is-success">
                <p class="title">Attending</p>
                <hr />
                <div v-for="nm in attending" :key="nm.name">
                  {{ nm.name }}
                </div>
                <!-- <p class="subtitle">name name nmememem</p> -->
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification is-danger">
                <p class="title">
                  Not Attending
                  <!-- <button class="is-info is-pulled-right"> -->
                  <b-icon
                    class="is-pulled-right"
                    icon="bullhorn"
                    @click.native="notify_nonattenders"
                  ></b-icon>
                  <!-- </button> -->
                </p>
                <hr />
                <div v-for="nm in notAttending" :key="nm.name">
                  {{ nm.name }}
                </div>
                <!-- <p class="subtitle">With an image</p> -->
              </article>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    const data = [
      { Name: "event one", Date: "12/20/20" },
      { Name: "event two", Date: "1/15/21" }
    ];

    return {
      isActive: false,
      data,
      name: "",
      title: "",
      coach: true,
      showSuccess: false,
      checkAttending: false,
      showAttending: true,
      isSwitched: false,
      isImageModalActive: false,
      isAttendanceModalActive: false,
      dateTime: new Date(),
      // isCardModalActive: true,
      isSwitchedCustom: "No",
      attending: [{ name: "Connor R" }, { name: "Morgan A" }],
      notAttending: [{ name: "Ben A" }, { name: "Cole C" }]
    };
  },
  computed: {
    ...mapState(["userProfile"])
  },
  methods: {
    toggle() {
      if (!this.showAttending) {
        this.showAttending = true;
      } else {
        this.showAttending = false;
      }
      // this.showAttending = !this.showAttending;
    },
    cancelCalendar() {
      this.isImageModalActive = false;
    },
    confirmCalendar() {
      this.isImageModalActive = false;
      console.log(this.dateTime);
    },

    submitGoal() {
      this.$buefy.notification.open("Clicked!!");
    },
    notify_nonattenders() {
      this.$buefy.toast.open("Notifications sent");
    },
    updateProfile() {
      this.$store.dispatch("updateProfile", {
        name: this.name !== "" ? this.name : this.userProfile.name,
        title: this.title !== "" ? this.title : this.userProfile.title
      });
      this.name = "";
      this.title = "";
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 2000);
    }
  }
};
</script>
