<template>
  <div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">
          <b-icon icon="account" size="is-medium"> </b-icon>
          Name
        </p>
        <!-- <p class="heading ">Name</p> -->
        <p>{{ userProfile.name }}</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">
          <b-icon icon="chevron-double-up" size="is-medium"> </b-icon>
          Rank
        </p>
        <!-- <p class="heading ">Rank</p> -->
        <p><br /></p>
        <div class="columns is-mobile is-centered">
          <div
            v-bind:style="{ background: activeColor }"
            class="column is-one-fourth notification "
          ></div>
        </div>
        <p></p>
        <!-- <p class="title">{{userProfile.title}}</p> -->
      </div>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">
          <b-icon icon="check-circle" size="is-medium"> </b-icon>
          Goals
        </p>
        <!-- <p class="heading">Goals</p> -->
        <!-- <p class="title">{{userProfile.name}}</p> -->
      </div>
    </div>
    <section class="mr-2 ml-2">
      <b-field grouped group-multiline> </b-field>

      <b-table :data="data" :mobile-cards="false">
        <b-table-column field="Type" label="Type" v-slot="props">
          {{ props.row.Type }}
        </b-table-column>

        <b-table-column field="stage" label="Stage" v-slot="props">
          {{ props.row.Stage }}
        </b-table-column>

        <b-table-column field="time" label="Time" centered v-slot="props">
          <span class="tag is-info is-light">
            {{ props.row.Time }}
          </span>
        </b-table-column>
      </b-table>
    </section>
    <section class="mr-2 ml-2">
      <hr class="solid" />
      <b-field grouped>
        <b-field label="Type">
          <b-select>
            <option>Firstshot</option>
            <option>String</option>
            <option>Match</option>
          </b-select>
        </b-field>
        <b-field label="Stage" expanded>
          <b-select>
            <option>Focus</option>
            <option>Go Fast</option>
            <option>In and Out</option>
            <option>Match</option>
          </b-select>
        </b-field>
        <b-field label="Time" expanded>
          <b-numberinput :controls="false" step="0.01" :min="0"></b-numberinput>
        </b-field>
      </b-field>
    </section>
    <section class=" mt-4 has-text-centered">
      <b-button
        @click="submitGoal"
        type="is-success is-large"
        icon-right="plus-circle"
      />
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    const data = [
      { Type: "String", Stage: "Go Fast", Time: 1.9 },
      { Type: "Stage", Stage: "M", Time: 10 }
      //     {'Type': 'Match', 'Stage': '', 'Time': 80}
    ];
    return {
      data,
      name: "",
      title: "",
      showSuccess: false,
      activeColor: "blue"
    };
  },
  computed: {
    ...mapState(["userProfile"])
  },
  methods: {
    submitGoal() {
      this.$buefy.notification.open("Clicked!!");
    },
    updateProfile() {
      this.$store.dispatch("getProfile", {
        name: this.name !== "" ? this.name : this.userProfile.name,
        title: this.title !== "" ? this.title : this.userProfile.title
      });
      this.name = "";
      this.title = "";
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 2000);
    }
  }
};
</script>
