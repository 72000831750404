<template>
  <div>
    <GChart
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      @ready="onChartReady"
    />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart
  },
  data() {
    return {
      chartData: null, // the chartData will be created once Chart is ready (needs google charts lib for this)
      chartOptions: {
        title: "Line intervals, default",
        height: 500,
        curveType: "function",
        lineWidth: 0,
        intervals: { style: "line", lineWidth: 0, barWidth: 0 },
        interval: {
          i3: {
            style: "points",
            color: "black",
            pointSize: 5,
            lineWidth: 0,
            fillOpacity: 0.7
          },
          i2: { style: "area", curveType: "function", fillOpacity: 0.3 }
        },
        legend: "none"
      }
    };
  },

  methods: {
    onChartReady(chart, google) {
      // now we have google lib loaded. Let's create data table based using it.
      this.createDataTable(google);
    },
    createDataTable(google) {
      const data = new google.visualization.DataTable();
      data.addColumn("string", "x");
      data.addColumn("number", "values");
      data.addColumn({ id: "i0", type: "number", role: "interval" });
      data.addColumn({ id: "i1", type: "number", role: "interval" });
      data.addColumn({ id: "i2", type: "number", role: "interval" });
      data.addColumn({ id: "i2", type: "number", role: "interval" });
      data.addColumn({ id: "i2", type: "number", role: "interval" });
      data.addColumn({ id: "i2", type: "number", role: "interval" });
      data.addColumn({ id: "i3", type: "number", role: "interval" });
      data.addColumn({ id: "i3", type: "number", role: "interval" });
      data.addColumn({ id: "i3", type: "number", role: "interval" });
      data.addColumn({ id: "i3", type: "number", role: "interval" });
      // data.addColumn({ id: "median", type: "number", role: "interval" });

      data.addRows([
        ["a", 100, 100, 110, 85, 96, 104, 120, 85, 96, 104, 120],
        ["b", 120, 95, 130, 90, 113, 124, 140, 90, 113, 124, 140],
        ["c", 130, 105, 140, 100, 117, 133, 139, 100, 117, 133, 139],
        ["d", 90, 85, 95, 85, 88, 92, 95, 85, 88, 92, 95],
        ["e", 70, 74, 63, 67, 69, 70, 72, 67, 69, 70, 72]
      ]);

      // Since the :data is reactive, we just need to update it's value
      this.chartData = data;
    }
  }
};
</script>

<style></style>
